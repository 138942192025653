const React = require('react')
const { MixcloudPlayer } = require('./src/components/MixcloudPlayer')

exports.wrapPageElement = ({ element }) => {
  return (
    <React.Fragment>
      {element}
      <MixcloudPlayer />
    </React.Fragment>
  )
}
