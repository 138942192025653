/** @jsx jsx */
import { jsx } from 'theme-ui'
import { a, useTransition } from 'react-spring'

import { useUIStore } from '@stores/ui'

export const MixcloudPlayer = () => {
  const slug = useUIStore((state) => state.playerSlug)

  const transition = useTransition(!!slug, {
    from: {
      y: '100%',
    },
    enter: {
      y: '0%',
    },
    leave: {
      y: '100%',
    },
  })

  return transition(
    (styles, item) =>
      item && (
        <a.div
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            bg: '#333',
            minHeight: 60,
            borderTop: '1px solid black',
          }}
          style={styles}
        >
          <iframe
            width="100%"
            height="60"
            title="Mixcloud embed"
            src={`https://www.mixcloud.com/widget/iframe/?hide_cover=1&autoplay=1&light=0&mini=1&feed=%2Fradioradioradioradio%2F${slug}%2F`}
            frameBorder="0"
            allow="autoplay"
            sx={{ display: 'block', border: 'none' }}
          ></iframe>

        </a.div>
      )
  )
}
