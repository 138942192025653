import create from 'zustand'
import produce from 'immer'

const immer = (config) => (set, get) => config((fn) => set(produce(fn)), get)

export const useUIStore = create(
  immer((set) => ({
    playerSlug: undefined,
    hasNext: undefined,
    streams: [],
    actions: {
      setPlayerURL: (url?: string) => {
        set((state) => {
          state.playerSlug = state.playerSlug === url ? undefined : url
        })
      },
      setStreams: (streams: any) => {
        set((state) => {
          state.streams = [...state.streams, ...streams]
        })
      },
      setHasNext: (hasNext: boolean) => {
        set((state) => {
          state.hasNext = hasNext
        })
      },
    },
  }))
)
